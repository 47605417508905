import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import moment from 'moment';
import 'antd/dist/antd.css';
import './assets/styles.css';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import AppRouter from './AppRouter';
import AuthProvider from './AuthProvider';
// import ErrorBoundary from './ErrorBoundary';

moment.suppressDeprecationWarnings = true;

Bugsnag.start({
  apiKey: '9e2f0b1bcd782beda3e3323f28ec37f3',
  plugins: [new BugsnagPluginReact()],
});
BugsnagPerformance.start({ apiKey: '9e2f0b1bcd782beda3e3323f28ec37f3' });

const App = props => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

  return (
    <Provider store={props.store}>
      <AuthProvider>
        <ErrorBoundary>
          <AppRouter />
        </ErrorBoundary>
      </AuthProvider>
    </Provider>
  );
};

App.propTypes = {
  store: PropTypes.object.isRequired,
};

export default App;
