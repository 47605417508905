import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row, Checkbox } from 'antd';
import moment from 'moment';
// eslint-disable-next-line import/no-cycle
import {
  TextArea,
  FormItem,
  DatePicker,
  TimePicker,
  Select,
  Input,
  GoogleMapAutocomplete,
} from 'components';
import { fetchInspectionTypes } from 'actions/inspection-types';
import { fetchInspetionTypesToUsers } from 'actions/inspetion-types-to-users';
import { getInspectionTypesState } from 'selectors/inspection-types';
import { getInspetionTypesToUsersState } from 'selectors/inspetion-types-to-users';
import formItemConfigs from './formItemConfigs';
import { ShortTime } from '../../constants/timeFormat';

const { Option } = Select;

const {
  addressInput,
  inspectionTypeInput,
  dateInput,
  timeInput,
  notesInput,
  addressLine2Input,
  customAddressInput,
  isCustomAddress,
} = formItemConfigs;

const BookingForm = ({ form, handleSubmit, booking = null }) => {
  const { getFieldDecorator } = form;
  const dispatch = useDispatch();
  const inspectionTypes = useSelector(getInspectionTypesState);
  const inspectionTypesUser = useSelector(getInspetionTypesToUsersState);

  const [allInspectionTypes, setAllInspectionTypes] = useState([]);
  const [googleAddress, setGoogleAddress] = useState();

  useEffect(() => {
    dispatch(fetchInspectionTypes.request({ getAll: true, '$sort[name]': 1 }));
    dispatch(fetchInspetionTypesToUsers.request({ getAll: true, '$sort[name]': 1 }));
    if (booking && booking.time) {
      form.setFieldsValue({ [timeInput.fieldName]: moment(booking.time, ShortTime) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    let options = [];
    if (inspectionTypes && inspectionTypes.data) {
      options = [...options, ...inspectionTypes.data];
    }
    if (inspectionTypesUser && inspectionTypesUser.data) {
      options = [...options, ...inspectionTypesUser.data];
    }
    setAllInspectionTypes(options.sort((a, b) => a.name.localeCompare(b.name)));
  }, [inspectionTypes, inspectionTypesUser]);

  const onPlaceSelected = value => {
    const { description } = value;
    form.setFieldsValue({ [addressInput.fieldName]: description });
  };

  return (
    <Form
      id='contactsForm'
      layout={'vertical'}
      labelAlign={'left'}
      hideRequiredMark
      onSubmit={handleSubmit}
    >
      <Row gutter={25}>
        {!googleAddress ? (
          <Col span={24}>
            <FormItem label={addressInput.label}>
              {getFieldDecorator(addressInput.fieldName, {
                ...addressInput.decoratorOptions,
                initialValue: booking ? booking.address : null,
              })(
                <GoogleMapAutocomplete
                  address={booking && booking.address}
                  onPlaceSelected={onPlaceSelected}
                />
              )}
            </FormItem>
          </Col>
        ) : (
          <Col span={24}>
            <FormItem label={customAddressInput.label}>
              {getFieldDecorator(customAddressInput.fieldName, {
                ...customAddressInput.decoratorOptions,
                initialValue: booking ? booking.address : null,
              })(<Input size={'large'} autocomplete='new-password' />)}
            </FormItem>
          </Col>
        )}

        <Col span={24}>
          <FormItem>
            {getFieldDecorator(isCustomAddress.fieldName, {
              ...isCustomAddress.decoratorOptions,
              initialValue: booking ? booking.isCustomAddress : null,
              valuePropName: 'checked',
            })(
              <Checkbox onChange={e => setGoogleAddress(e.target.checked)}>
                The address is not found by Google
              </Checkbox>
            )}
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem label={addressLine2Input.label}>
            {getFieldDecorator(addressLine2Input.fieldName, {
              ...addressLine2Input.decoratorOptions,
              initialValue: booking ? booking.addressLine2 : null,
            })(<Input size={'large'} autocomplete='new-password' />)}
          </FormItem>
        </Col>

        <Col xs={24} md={9}>
          <FormItem label={inspectionTypeInput.label}>
            {getFieldDecorator(inspectionTypeInput.fieldName, {
              ...inspectionTypeInput.decoratorOptions,
              initialValue: booking ? booking.inspectionTypeId : null,
            })(
              <Select size={'large'} style={{ width: '100%' }} optionLabelProp='label'>
                {allInspectionTypes.map(item => (
                  <Option value={item.id} label={item.name} key={item.id} title={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>
        </Col>
        <Col xs={24} md={9}>
          <FormItem label={dateInput.label}>
            {getFieldDecorator(dateInput.fieldName, {
              ...dateInput.decoratorOptions,
              initialValue: booking ? moment(booking.date, 'YYYY-MM-DD') : null,
            })(
              <DatePicker
                size={'large'}
                format={'YYYY-MM-DD'}
                disabledDate={current => {
                  return moment().add(-180, 'days') >= current;
                }}
              />
            )}
          </FormItem>
        </Col>

        <Col xs={24} md={6}>
          <FormItem label={timeInput.label}>
            {getFieldDecorator(timeInput.fieldName, {
              ...timeInput.decoratorOptions,
              initialValue: booking ? moment(booking.time, ShortTime) : null,
            })(
              <TimePicker
                size={'large'}
                use12Hours
                defaultOpenValue={moment('00:00', ShortTime)}
                format={ShortTime}
                minuteStep={15}
              />
            )}
          </FormItem>
        </Col>
      </Row>

      <FormItem label={notesInput.label}>
        {getFieldDecorator(notesInput.fieldName, {
          ...notesInput.decoratorOptions,
          initialValue: booking ? booking.notes : null,
        })(<TextArea size={'large'} rows='4' />)}
      </FormItem>
    </Form>
  );
};

export default React.memo(BookingForm);
