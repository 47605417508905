import React from 'react';
import styled from 'styled-components';
import { Modal, Spin } from 'antd';

const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StyledVideo = styled.video`
  max-width: 100%;
  max-height: 80vh;
`;

const SpinnerWrapper = styled.div`
  padding: 130px 50px;
  text-align: center;
`;

const VideoPlayer = ({ video, visible, onClose, loading }) => {
  if (loading) {
    return (
      <SpinnerWrapper>
        <Spin size='large' />
      </SpinnerWrapper>
    );
  }
  if (!video || !video.url) {
    return <div>Video content is not available at the moment.</div>;
  }
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      width='80%'
      bodyStyle={{ padding: '24px 0' }}
    >
      <VideoWrapper>
        <StyledVideo controls onError={e => console.error(e)}>
          <source src={video?.url} type={video?.type || 'video/mp4'} />
          Your browser does not support the video tag.
        </StyledVideo>
      </VideoWrapper>
    </Modal>
  );
};

export default React.memo(VideoPlayer);
