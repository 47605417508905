import React from 'react';
import styled, { css } from 'styled-components';
import { Upload as upload, Spin, Icon } from 'antd';
import { Notification } from 'components';
import Compressor from 'compressorjs';

const Upload = styled(upload)`
  .ant-upload-select-picture-card {
    display: flex;
    height: 35px;
    width: 35px;
  }

  ${props =>
    props.hideUpload &&
    css`
      .ant-upload-select-picture-card {
        display: none;
      }
    `}
`;

const MediaUpload = ({
  fileList = [],
  changeHandler,
  loading,
  extensions,
  maxAmount,
  multiple,
  onPreview,
  fileSize = 100,
  hideUpload,
  ...otherProps
}) => {
  const addFile = (file, files) => {
    Promise.all(
      files.map(f => {
        // eslint-disable-next-line no-new
        return new Promise((resolve, reject) => {
          // eslint-disable-next-line no-new
          new Compressor(f, {
            quality: f.size > 2 ? 0.2 : 0.6,
            success(result) {
              resolve(result);
            },
            error(err) {
              reject(err);
            },
          });
        });
      })
    ).then(
      selectedFileList => {
        const hasBigFiles = selectedFileList.find(f => f.size > fileSize * 1024 * 1024);
        const hasRestrictedExtensions = !!selectedFileList.filter(
          f =>
            extensions &&
            !extensions.includes(
              f.name
                .split('.')
                .pop()
                .toLowerCase()
            )
        ).length;
        if (
          (!multiple && fileList.length >= 1) ||
          fileList.length + selectedFileList.length > maxAmount ||
          hasBigFiles
        ) {
          Notification.warning(
            `Maximum ${maxAmount || (!multiple && 1)} files allowed, ${fileSize} MB each`
          );
        } else if (hasRestrictedExtensions) {
          Notification.warning('Unsupported extension!');
        } else {
          changeHandler &&
            changeHandler(selectedFileList, [...fileList, ...selectedFileList], 'add');
        }
      },
      reason => {
        Notification.warning(reason);
      }
    );

    return false;
  };

  const deleteFile = file => {
    changeHandler &&
      changeHandler(
        file,
        fileList.filter(f => f.uid !== file.uid),
        'delete'
      );
  };

  return (
    <>
      <Spin spinning={!!loading}>
        <Upload
          listType='picture-card'
          fileList={fileList}
          onPreview={onPreview}
          beforeUpload={addFile}
          onRemove={deleteFile}
          hideUpload={hideUpload}
          multiple={multiple}
          {...otherProps}
        >
          <div>
            <div className='ant-upload-text'>
              <Icon type='camera' style={{ fontSize: '15px', margin: '0px', color: '#D9D9D9' }} />
            </div>
          </div>
        </Upload>
      </Spin>
    </>
  );
};

export default React.memo(MediaUpload);
