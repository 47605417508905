import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';
import { ActionButtons, Table, Notification } from 'components/index';

import { deleteBooking } from 'actions/bookings';
import {
  bookingsValuesLoading,
  getUpdateBookingLoading,
  getUpdateSuccessState,
} from 'selectors/bookings';
import {
  addBookingInfo,
  addBrokerInfo,
  addContactInfo,
  addReportInfo,
  cleanAll,
} from 'actions/booking-form';
import BookingModalContact from 'components/AddBooking/_bookingmodalcontent';
import moment from 'moment';
import { getUserRole } from '../../selectors/users';
import store from '../../configs/configureStore';
import { ShortTime } from '../../constants/timeFormat';

const { confirm } = Modal;

const BookingsTable = ({ bookings }) => {
  const dispatch = useDispatch();
  const loading = useSelector(bookingsValuesLoading);
  const updateBookingLoading = useSelector(getUpdateBookingLoading);
  const success = useSelector(getUpdateSuccessState);
  const [modalVisible, setModalVisible] = useState(false);

  const showDeleteDialog = useCallback(
    booking => {
      confirm({
        title: `Remove booking #${booking.id}?`,
        okType: 'danger',
        onOk() {
          dispatch(deleteBooking.request({ id: booking.id }));
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirm, dispatch]
  );

  const showEditModal = data => {
    const { report, contact, broker, ...booking } = data;

    dispatch(
      addBookingInfo.request({
        ...booking,
        date: moment(booking.date),
      })
    );
    dispatch(addReportInfo.request(report));
    dispatch(addContactInfo.request(contact));
    dispatch(addBrokerInfo.request(broker));
    setModalVisible(true);
  };

  useEffect(() => {
    if (!updateBookingLoading && typeof updateBookingLoading !== 'undefined' && success) {
      Notification.info('Booking updated');
      setModalVisible(false);
      dispatch(cleanAll.request());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateBookingLoading, success]);

  const tableOptions = {
    loading,
  };

  const columns = [
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      render: text => {
        return (
          <div>
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(text)}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <EnvironmentOutlined style={{ color: '#1890ff' }} />
            </a>{' '}
            {text.replace(', ON, Canada', '')}
          </div>
        );
      },
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: text => {
        return moment(text).format('LL');
      },
    },
    {
      title: 'Start Time',
      dataIndex: 'time',
      key: 'time',
      render: text => {
        return moment(text, 'HH:mm').format(ShortTime);
      },
    },
    {
      title: 'End Time',
      dataIndex: 'endtime',
      key: 'endtime',
    },
  ];

  const mobileColumns = [
    {
      title: 'Booking Details',
      dataIndex: 'address',
      key: 'address',
      render: (text, record) => {
        return (
          <>
            <p>
              <div>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    text
                  )}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <EnvironmentOutlined style={{ color: '#1890ff' }} />
                </a>{' '}
                <b>{record.address}</b>
              </div>
            </p>
            <p>{moment(record.date).format('LL')}</p>
            <p>{moment(record.time, 'HH:mm').format(ShortTime)}</p>
          </>
        );
      },
    },
  ];

  const actionsColumn = {
    title: ' ',
    dataIndex: 'edit',
    key: 'edit',
    width: 24,
    render: (text, record) => {
      const actions = [
        {
          key: `edit${record.id}`,
          label: 'Edit Booking',
          onClick: () => showEditModal(record),
        },
        {
          key: `devider${record.id}`,
          action: 'devider',
        },
        {
          key: `delete${record.id}`,
          label: 'Delete Booking',
          onClick: () => showDeleteDialog(record),
        },
      ];
      const currentRole = getUserRole(store.getState());
      if (currentRole === 'inspector') actions.pop();
      return <ActionButtons record={record} text={text} actions={actions} />;
    },
  };

  columns.push(actionsColumn);
  mobileColumns.push(actionsColumn);

  return (
    <>
      {modalVisible && (
        <Modal
          width={'80%'}
          bodyStyle={{ height: '100%' }}
          footer={null}
          title={null}
          visible={modalVisible}
          onCancel={() => {
            setModalVisible(false);
            dispatch(cleanAll.request());
          }}
        >
          <BookingModalContact visibility={modalVisible} mode='edit' closeModal={setModalVisible} />
        </Modal>
      )}

      <Table
        columns={columns}
        mobileColumns={mobileColumns}
        {...tableOptions}
        dataSource={bookings || []}
        rowKey='id'
      />
    </>
  );
};

export default React.memo(BookingsTable);
