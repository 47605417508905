import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BookingForm } from 'components';
import { Form } from 'antd';
import { getCurrent, getIsSubmitted, getBooking } from 'selectors/booking-form';
import { cancelSubmit, addBookingInfo } from 'actions/booking-form';
import moment from 'moment';
import { ShortTime } from '../../../constants/timeFormat';

const GeneralInfo = ({ form, handleChangeSlide }) => {
  const dispatch = useDispatch();
  const current = useSelector(getCurrent);
  const booking = useSelector(getBooking);
  const isSubmitted = useSelector(getIsSubmitted);

  useEffect(() => {
    if (isSubmitted) {
      form.validateFields((err, values) => {
        const modifiedValues = {
          ...values,
          time: moment(values.time).format(ShortTime),
        };

        if (booking) {
          modifiedValues.id = booking.id;
          modifiedValues.contactId = booking.contactId;
          modifiedValues.createdById = booking.createdById;
          modifiedValues.reportId = booking.reportId;
          modifiedValues.contractId = booking.contractId;
          modifiedValues.invoiceId = booking.invoiceId;
          modifiedValues.brokerId = booking.brokerId;
        }
        if (!err) {
          dispatch(
            addBookingInfo.request({
              ...modifiedValues,
              googleCalendarEventId: booking?.googleCalendarEventId,
            })
          );
          handleChangeSlide(current + 1);
        } else {
          dispatch(cancelSubmit.request());
        }

        return false;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted]);

  return (
    <div className='general-info'>
      <BookingForm form={form} booking={booking} />
    </div>
  );
};

export default React.memo(Form.create({ name: 'general_info_form' })(GeneralInfo));
