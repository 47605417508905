import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormItem,
  InputNumber,
  Select as StyledSelect,
  Input,
  Button,
  Notification,
  Modal,
} from 'components';
import { Col, Form, Row, Select } from 'antd';
import { getUsers } from 'actions/users';
import { fetchContacts } from 'actions/contacts';
import { fetchBrokers } from 'actions/brokers';
import { getUsers as getUsersData, usersError, usersLoading } from 'selectors/users';
import { getContactsState } from 'selectors/contacts';
import { getBrokersState } from 'selectors/brokers';
import { getBuildingTypesState } from 'selectors/building-types';
import { getBuildingTypesToUsersState } from 'selectors/building-types-to-users';
import { getDwellingsState } from 'selectors/dwellings';
import { getDwellingsToUsersState } from 'selectors/dwellings-to-users';
import { fetchBuildingTypes } from 'actions/building-types';
import { fetchBuildingTypesToUsers } from 'actions/building-types-to-users';
import { fetchDwellings } from 'actions/dwellings';
import { fetchDwellingsToUsers } from 'actions/dwellings-to-users';
import { isUserPermitted } from 'configs/isUserPermitted';
import { roles } from 'constants/users';
import { resentReport } from 'actions/reports';
import { getSuccessState } from 'selectors/reports';
import { TINY_MCE_PATH } from 'configs/env-vars';
import { Editor } from '@tinymce/tinymce-react';
import { updateReportApi } from 'services/reports';
import formItemConfigs from './partials/formItemConfigs';
import ReportsFormWrapper from './partials/ReportsFormWrapper';
import CoverField from './partials/CoverFIeld';

const { Option } = Select;

const {
  inspectorIdInput,
  clientNameInput,
  brokerNameInput,
  addressInput,
  buildingTypeIdInput,
  dwellingIdInput,
  storiesInput,
  roomsInput,
  bedroomsInput,
  bathroomsInput,
  yearBuiltInput,
  addressLine2Input,
  coverInput,
  sqft,
  // statusInput,
} = formItemConfigs;

const ReportsForm = ({ handleSubmit, form, report, isEditPage, handleChange = () => {} }) => {
  const editorRef = useRef(null);
  const { getFieldDecorator } = form;
  const dispatch = useDispatch();
  const users = useSelector(getUsersData);
  const contacts = useSelector(getContactsState);
  const brokers = useSelector(getBrokersState);
  const buildingTypes = useSelector(getBuildingTypesState);
  const buildingTypesUsers = useSelector(getBuildingTypesToUsersState);
  const usersValuesLoading = useSelector(usersLoading);
  const usersValuesError = useSelector(usersError);
  const dwellings = useSelector(getDwellingsState);
  const dwellingsToUsers = useSelector(getDwellingsToUsersState);
  const success = useSelector(getSuccessState);

  const [allBuildingTypes, setAllBuildingTypes] = useState([]);
  const [allDwellings, setAllDwellings] = useState([]);
  const [isAdviceModal, setIsAdviceModal] = useState(false);
  const [editorText, setEditorText] = useState(null);

  useEffect(() => {
    dispatch(getUsers.request({ getAll: true, role: 'inspector', '$sort[firstName]': 1 }));
    dispatch(fetchContacts.request({ getAll: true, '$sort[name]': 1 }));
    dispatch(fetchBrokers.request({ getAll: true, '$sort[name]': 1 }));
    dispatch(fetchBuildingTypes.request({ getAll: true, '$sort[name]': 1 }));
    dispatch(fetchBuildingTypesToUsers.request({ getAll: true, '$sort[name]': 1 }));
    dispatch(fetchDwellings.request({ getAll: true, '$sort[name]': 1 }));
    dispatch(fetchDwellingsToUsers.request({ getAll: true, '$sort[name]': 1 }));
    // fetchOneReportApi({ id: report?.id }).then(res => console.log('res', res));
  }, [dispatch]);

  useEffect(() => {
    let options = [];
    if (buildingTypes && buildingTypes.data) {
      options = [...options, ...buildingTypes.data];
    }
    if (buildingTypesUsers && buildingTypesUsers.data) {
      options = [...options, ...buildingTypesUsers.data];
    }
    setAllBuildingTypes(options.sort((a, b) => a.name.localeCompare(b.name)));
  }, [buildingTypes, buildingTypesUsers]);

  useEffect(() => {
    let options = [];
    if (dwellings && dwellings.data) {
      options = [...options, ...dwellings.data];
    }
    if (dwellingsToUsers && dwellingsToUsers.data) {
      options = [...options, ...dwellingsToUsers.data];
    }
    setAllDwellings(options.sort((a, b) => a.name.localeCompare(b.name)));
  }, [dwellings, dwellingsToUsers]);

  const phoneNumbers = report?.booking?.contact?.contact_phone_numbers;

  return (
    <ReportsFormWrapper>
      <Form
        id='reportsForm'
        layout={'vertical'}
        labelAlign={'left'}
        hideRequiredMark
        onSubmit={handleSubmit}
      >
        {isUserPermitted([roles.ROLE_SUPER_ADMIN, roles.ROLE_MANAGER]) && (
          <FormItem label={inspectorIdInput.label}>
            {getFieldDecorator(inspectorIdInput.fieldName, {
              ...inspectorIdInput.decoratorOptions,
              initialValue: report ? report[inspectorIdInput.fieldName] : null,
            })(
              <StyledSelect
                size={'large'}
                style={{ width: '100%' }}
                onChange={value => {
                  handleChange('inspectorId', value);
                }}
              >
                {!usersValuesLoading &&
                  !usersValuesError &&
                  users.data.map(
                    user =>
                      user?.isVerified && (
                        <Option
                          value={user?.id}
                          label={`${user?.firstName} ${user?.lastName}`}
                          key={user?.id}
                        >
                          {user?.role === 'inspector' ? <b>Inspector - </b> : <b>Manager - </b>}
                          {user?.firstName} {user?.lastName}
                        </Option>
                      )
                  )}
              </StyledSelect>
            )}
          </FormItem>
        )}
        {isEditPage && (
          <>
            <FormItem label={clientNameInput.label}>
              {getFieldDecorator(clientNameInput.fieldName, {
                ...clientNameInput.decoratorOptions,
                initialValue: report.booking?.contact?.id,
              })(
                <StyledSelect
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  size={'large'}
                  style={{ width: '100%' }}
                  onChange={value => {
                    handleChange('contactId', value);
                  }}
                >
                  {contacts &&
                    contacts?.data &&
                    contacts?.data.map(contact => (
                      <Option value={contact?.id} label={contact?.name} key={contact?.id}>
                        {contact?.name}
                      </Option>
                    ))}
                </StyledSelect>
              )}
            </FormItem>
            <>
              <p className='clientsPhoneNumber'>Client Phone Number(s)</p>
            </>

            {phoneNumbers ? (
              <div className='noClientsPhoneNumber '>
                {phoneNumbers?.map((item, index) => {
                  return (
                    <a href={`tel:${item.phone}`} key={index} className='phoneNumberText'>
                      {(index ? ', ' : '') + item.phone}
                    </a>
                  );
                })}{' '}
              </div>
            ) : (
              <p className='noClientsPhoneNumber'>N/A</p>
            )}

            <FormItem label={brokerNameInput.label}>
              {getFieldDecorator(brokerNameInput.fieldName, {
                ...brokerNameInput.decoratorOptions,
                initialValue:
                  report && report.booking && report.booking.broker
                    ? report.booking.broker.name
                    : null,
              })(
                <StyledSelect
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  size={'large'}
                  style={{ width: '100%' }}
                  onChange={value => {
                    handleChange('brokerId', value);
                  }}
                >
                  {brokers &&
                    brokers.data &&
                    brokers.data.map(broker => (
                      <Option value={broker?.id} label={broker?.name} key={broker?.id}>
                        {broker?.name}
                      </Option>
                    ))}
                </StyledSelect>
              )}
            </FormItem>
            <FormItem label={addressInput.label}>
              {getFieldDecorator(addressInput.fieldName, {
                ...addressInput.decoratorOptions,
                initialValue: report && report.booking ? report.booking.address : null,
              })(<Input size={'large'} style={{ width: '100%' }} disabled />)}
            </FormItem>
            <FormItem label={addressLine2Input.label}>
              {getFieldDecorator(addressLine2Input.fieldName, {
                ...addressLine2Input.decoratorOptions,
                initialValue: report && report.booking ? report.booking.addressLine2 : null,
              })(<Input size={'large'} style={{ width: '100%' }} disabled />)}
            </FormItem>
          </>
        )}
        <FormItem label={buildingTypeIdInput.label}>
          {getFieldDecorator(buildingTypeIdInput.fieldName, {
            ...buildingTypeIdInput.decoratorOptions,
            initialValue: report ? report[buildingTypeIdInput.fieldName] : null,
          })(
            <Select
              size={'large'}
              style={{ width: '100%' }}
              optionLabelProp='label'
              onChange={value => {
                handleChange('buildingTypeId', value);
              }}
            >
              {allBuildingTypes?.map(value => (
                <Option value={value?.id} label={value?.name} key={value?.id}>
                  {value?.name}
                </Option>
              ))}
            </Select>
          )}
        </FormItem>
        <FormItem label={dwellingIdInput.label}>
          {getFieldDecorator(dwellingIdInput.fieldName, {
            ...dwellingIdInput.decoratorOptions,
            initialValue: report ? report[dwellingIdInput.fieldName] : null,
          })(
            <Select
              size={'large'}
              style={{ width: '100%' }}
              optionLabelProp='label'
              onChange={value => {
                handleChange('dwellingId', value);
              }}
            >
              {allDwellings?.map(value => (
                <Option value={value?.id} label={value?.name} key={value?.id}>
                  {value?.name}
                </Option>
              ))}
            </Select>
          )}
        </FormItem>

        <Row gutter={25}>
          <Col xs={12} lg={4}>
            <FormItem label={storiesInput.label} className='number-inputs'>
              {getFieldDecorator(storiesInput.fieldName, {
                ...storiesInput.decoratorOptions,
                initialValue: report ? report[storiesInput.fieldName] : null,
              })(
                <InputNumber
                  onChange={value => {
                    handleChange('stories', value);
                  }}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={12} lg={4}>
            <FormItem label={roomsInput.label} className='number-inputs'>
              {getFieldDecorator(roomsInput.fieldName, {
                ...roomsInput.decoratorOptions,
                initialValue: report ? report[roomsInput.fieldName] : null,
              })(
                <InputNumber
                  onChange={value => {
                    handleChange('rooms', value);
                  }}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={12} lg={4}>
            <FormItem label={bedroomsInput.label} className='number-inputs'>
              {getFieldDecorator(bedroomsInput.fieldName, {
                ...bedroomsInput.decoratorOptions,
                initialValue: report ? report[bedroomsInput.fieldName] : null,
              })(
                <InputNumber
                  onChange={value => {
                    handleChange('bedrooms', value);
                  }}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={12} lg={4}>
            <FormItem label={bathroomsInput.label} className='number-inputs'>
              {getFieldDecorator(bathroomsInput.fieldName, {
                ...bathroomsInput.decoratorOptions,
                initialValue: report ? report[bathroomsInput.fieldName] : null,
              })(
                <InputNumber
                  onChange={value => {
                    handleChange('bathrooms', value);
                  }}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={12} lg={4}>
            <FormItem label={yearBuiltInput.label} className='number-inputs'>
              {getFieldDecorator(yearBuiltInput.fieldName, {
                ...yearBuiltInput.decoratorOptions,
                initialValue: report ? report[yearBuiltInput.fieldName] : null,
              })(
                <InputNumber
                  style={{ width: '90px' }}
                  onChange={value => {
                    handleChange('yearBuilt', value);
                  }}
                />
              )}
            </FormItem>
          </Col>

          <Col xs={12} lg={4}>
            <FormItem label={sqft.label} className='number-inputs'>
              {getFieldDecorator(sqft.fieldName, {
                ...sqft.decoratorOptions,
                initialValue: report ? report[sqft.fieldName] : null,
              })(
                <InputNumber
                  style={{ width: '90px' }}
                  onChange={value => {
                    handleChange('sqft', value);
                  }}
                />
              )}
            </FormItem>
          </Col>
        </Row>

        {isEditPage && (
          <div className='cover-resent-row-wrapper'>
            <FormItem label={coverInput.label}>
              {getFieldDecorator(coverInput.fieldName, {
                ...coverInput.decoratorOptions,
                initialValue: report && report.cover ? report.cover : null,
              })(<CoverField form={form} report={report} handleChange={handleChange} />)}
            </FormItem>
            <div>
              <Button
                style={{ marginRight: '20px' }}
                onClick={() => {
                  setIsAdviceModal(true);
                }}
              >
                Inspector's Executive Summary
              </Button>
              {report?.contractStatus?.status !== 'signed' && (
                <Button
                  type='primary'
                  onClick={() => {
                    dispatch(
                      resentReport.request({
                        id: report.id,
                        values: {
                          id: report.id,
                          action: {
                            booking: { ...report?.booking, report },
                            type: 'resendContract',
                          },
                        },
                      })
                    );
                    !success && Notification.info('Report resent');
                  }}
                >
                  Resent
                </Button>
              )}
            </div>
          </div>
        )}
      </Form>
      <Modal
        title="Inspector's Executive Summary"
        visible={isAdviceModal}
        onCancel={() => setIsAdviceModal(false)}
        onOk={() => {
          const modifiedValues = {
            ...report,
            document: {
              ...report?.document,
              fields: {
                ...report?.document.fields,
                advice: editorText,
              },
            },
          };
          updateReportApi({
            id: report.id,
            values: modifiedValues,
          });
          setIsAdviceModal(false);
        }}
      >
        <Editor
          tinymceScriptSrc={TINY_MCE_PATH}
          onInit={(evt, editor) => {
            editorRef.current = editor;
          }}
          initialValue={report?.document?.fields?.advice}
          onEditorChange={e => setEditorText(e)}
          init={{
            min_height: 500,
            menubar: true,
            plugins: [
              'a11ychecker',
              'advlist',
              'advcode',
              'advtable',
              'autolink',
              'checklist',
              'export',
              'lists',
              'link',
              'image',
              'charmap',
              'preview',
              'anchor',
              'searchreplace',
              'visualblocks',
              'powerpaste',
              'fullscreen',
              'formatpainter',
              'insertdatetime',
              'media',
              'table',
              'help',
              'wordcount',
              'tinycomments',
              'pagebreak',
            ],
            tinycomments_mode: 'embedded',

            toolbar:
              'undo redo | casechange blocks | bold italic backcolor | ' +
              'alignleft aligncenter alignright alignjustify | ' +
              'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help | addcomment showcomments export | pagebreak',
            content_style: 'body { font-family:sans-serif; font-size:14px }',
            contextmenu: 'past',
          }}
        />
      </Modal>
    </ReportsFormWrapper>
  );
};

export default React.memo(ReportsForm);
